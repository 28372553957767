import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotificationScreenComponent } from './Notification/notification-screen/notification-screen.component';
import { AppComponent } from './app.component';
import { BlockchainTransferCardComponent } from './create-package/common/blockchain-transfer-card/blockchain-transfer-card.component';
import { RadioButtonComponent } from './create-package/common/radio-button/radio-button.component';
import { TextAreaComponent } from './create-package/common/text-area/text-area.component';
import { LoginGuard } from './home/auth/login.guard';
import { ForgotPasswordComponent } from './home/forgot-password/forgot-password.component';
import { LoginComponent } from './home/login/login.component';
import { SetupAccountComponent } from './home/setup-account/setup-account.component';
import { HomeLayoutComponent } from './layouts/home-layout.component';
import { BlockchainKeyGenerarteAndVerifyComponent } from './onboarding-dashboard/blockchain-key/blockchain-key-generarte-and-verify/blockchain-key-generarte-and-verify.component';
import { FilterOffcanvasComponent } from './package-listing/common/filter-offcanvas/filter-offcanvas.component';
import { RedirectionFailureComponent } from './redirection-failure.component';
import { RedirectionSuccessComponent } from './redirection-success.component';
import { RouteConstants } from './share/constants/routes.constants';
import { ConfigGuard } from './share/guard/config.guard';
import { BulkListCommonComponent } from './user-management/common/bulk-list-common/bulk-list-common.component';
import { BulkUploadListComponent } from './user-management/common/bulk-upload-list/bulk-upload-list.component';
import { OffcanvasHeaderComponent } from './utilities/offcanvas-header/offcanvas-header.component';
import { SideNavBarComponent } from './utilities/side-nav-bar/side-nav-bar.component';
import { LookupComponent } from './lookup/lookup.component';
import { ContactsComponent } from './lookup/contacts/contacts.component';
import { PublicEsignComponent } from './admin/sdex/public-esign/public-esign.component';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [LoginGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
        canActivate: [ConfigGuard],
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
        canActivate: [ConfigGuard],
      },
      {
        path: RouteConstants.LrTemplateGeneration,
        loadChildren: () =>
          import('./lr-templates/lr-templates.module').then(
            (m) => m.LrTemplatesModule
          ),
        canActivate: [ConfigGuard],
      },
      {
        path: RouteConstants.Package,
        loadChildren: () =>
          import('./packages/packages.module').then((m) => m.PackagesModule),
        canActivate: [ConfigGuard],
      },
      {
        path: RouteConstants.UserSetting,
        loadChildren: () =>
          import('./user-settings/user-settings.module').then(
            (m) => m.UserSettingsModule
          ),
        canActivate: [ConfigGuard],
      },
      {
        path: RouteConstants.DocumentValut,
        loadChildren: () =>
          import('./document-valut/document-valut.module').then(
            (m) => m.DocumentValutModule
          ),
        canActivate: [ConfigGuard],
      },
      {
        path : RouteConstants.DamsDashboard,
        loadChildren: () => import('./document-valut/document-vault-dashboard/document-vault-dashboard.module').then((m)=>m.DocumentVaultDashboardModule)
      },
      {
        path: RouteConstants.Roles,
        loadChildren: () =>
          import('./roles/roles.module').then((m) => m.RolesModule),
        canActivate: [ConfigGuard],
      },
      {
        path : RouteConstants.OrganizationStructure,
        loadChildren: () => import('./organisation-structure/organisation-structure.module').then((m)=>m.OrganisationStructureModule)
      },
       {
        path : RouteConstants.EwayBill,
        loadChildren: () => import('./eway-bill/eway-bill.module').then((m)=>m.EwayBillModule)
      },
       {
        path : RouteConstants.Iba,
        loadChildren: () => import('./iba/iba.module').then((m)=>m.IbaModule)
      },
       {
        path : RouteConstants.Einvoice,
        loadChildren: () => import('./e-invoice/einvoice.module').then((m)=>m.EinvoiceModule)
      },
      {
        path : RouteConstants.Contract,
        loadChildren: () => import('./contract/contract.module').then((m)=>m.ContractModule)
      },
      {
        path : RouteConstants.ContractsTemplate,
        loadChildren: () => import('./contract-templates/contract-templates.module').then((m)=>m.ContractTemplatesModule)
      },
      {
        path: RouteConstants.Lookup,
        component: LookupComponent,
      },
      {
        path: RouteConstants.ContactsComponent,
        component : ContactsComponent,
      },
      {
        path : RouteConstants.ContractDashboard,
        loadChildren : () => import('./contracts-dashboard/contracts-dashboard.module').then((m)=>m.ContractsDashboardModule)
      },
      {
        path : RouteConstants.CmsPreferences,
        loadChildren : () => import('./cms-preferences/cms-preferences.module').then((m)=>m.CmsPreferencesModule)
      },
      {
        path : RouteConstants.FileBulkUpload,
        loadChildren : () => import('./document-valut/bulkupload/bulkupload.module').then((m)=>m.BulkuploadModule)
      },
      {
        path : RouteConstants.PublicEsignComponent,
        component: PublicEsignComponent
      },
      {
        path : RouteConstants.EKycSearchList,
        loadChildren :() => import('./admin/company/ekyc-search-list/ekyc-search-list.module').then((m)=>m.EkycSearchListModule)
      },
      {
        path : RouteConstants.StudentList,
        loadChildren :() => import('./student-list/student-list.module').then((m)=>m.StudentListModule)
      }
    ],
  },
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'setup-account',
        component: SetupAccountComponent,
      },
      {
        path: 'redirection-failure',
        component: RedirectionFailureComponent,
      },
      {
        path: 'redirection-success',
        component: RedirectionSuccessComponent,
      },
    ],
  },
  { path: 'bulk-list', component: BulkUploadListComponent },
  { path: 'bulk-list-common', component: BulkListCommonComponent },
  { path: 'side-nav-bar', component: SideNavBarComponent },
  { path: 'blockchainstatus-card', component: BlockchainTransferCardComponent },
  { path: 'radio-button', component: RadioButtonComponent },
  { path: 'textarea', component: TextAreaComponent },
  { path: 'blockchain', component: BlockchainKeyGenerarteAndVerifyComponent },
  { path: 'offcanvas-header', component: OffcanvasHeaderComponent },
  { path: 'filter',  component: FilterOffcanvasComponent },
  { path:'notification' , component: NotificationScreenComponent},
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
