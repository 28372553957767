export const environment = {
    production: false,
    rolesDisabled: false,
    scanDisabled: false,
    envType: 'DAMS',
    API_URL: `https://api-gateway.dev.sdex.in/api`,
    API_ESIGN_URL: `https://api.internal.sdex.in/public-esign`,
    IMAGE_URL: `https://axivise-images.s3.ap-south-1.amazonaws.com/app/`,
    PRIVATE_KEY : 'EQb8OsCjwgi8cTJikMWTXx5o4KXXG6dC',
    Dynamsoft: {
      resourcesPath: 'assets/dwt-resources',
      dwtProductKey: 't01898AUAAJf8GMRi4e5S2GDOMHDo6XrZQ0ychHLTBaEArjYpARWGo+I4kzU2GELivbqwbcvcgX/bOTu5oHlTPrIlsN0O3EDSHr+c7ODU9k5Fe6d0cPKWExjrMC27Heq8MoEz8KqAbsdhA8QCSy0H4B3X2sQD6AHqAOrVIB5wuYrzzyeOAbFf/9nQ4mQHp7Z32oC0cUoHJ285c0CGhDDl1U5LQMSenB1AD9BLQNaL7BQQHAF6gJ4A5kyPJL5K3CrM',
      uploadTargetURL: ''
    }
  };
  